import Backbone from 'backbone'
import { configureBreakpoints } from "util/breakpoints"
import LoginRouter from 'app/login_router'
import InviteRouter from 'app/invite_router'
import { getQuerystringParam, MobileDevice } from 'util/twistle'
import $ from 'jquery'
import Account from 'app/models/account'
import Config from 'app/models/config'
import MobileAppInstallView from 'app/views/mobile_app_install_view'

/**
 * Set up and render the login or registration UI.
 * @param  {Object} accountData
 * @param  {Object} appConfigData
 * @param  {Boolean} inviteMode
 */
export default function loadWebApp(accountData, appConfigData, inviteMode) {
    App.account = new Account(accountData);
    App.config = new Config(appConfigData);

    configureBreakpoints();

    // clear local settings
    App.config.attributes.localSettings = {};
    App.config.persistLocalSettings();

    const platformHasMobileApp = MobileDevice.iPhone() || MobileDevice.Android() || MobileDevice.iPad();

    if (platformHasMobileApp && !appConfigData.disableMobileAppReg) {
        displayMobileAppInstallInterstitial(App.config, inviteMode);
    }
    else {
        displayUI(inviteMode);
    }
}


/**
 * Displays the login setup UI.
 */
function displayUI(inviteMode) {
    App.loginDesktopView = new App.LoginDesktopView();
    App.baseBehavior = new App.BaseBehavior();

    if (inviteMode) {
        App.router = new InviteRouter();
    }
    else {
        App.router = new LoginRouter();
    }
    Backbone.history.start({ pushState: true });
}


/**
 * Attempts to display an interstitial dialog that directs the user to download
 * a native app from the App Store or Play Store, depending on their type of device.
 * @param  {Object} appConfig Probably App.config.
 */
 function displayMobileAppInstallInterstitial(appConfig, inviteMode) {
    const destination = getQuerystringParam("dest", false);
    const redirectAppUrl = getRedirectAppURL(destination, appConfig.get('nativeAppURLScheme'), appConfig.get("regToken"));

    if (!redirectAppUrl) {
        displayUI(inviteMode);
        return;
    }

    let d = new MobileAppInstallView({
        isRegistration: inRegistrationMode(destination, appConfig.get("regToken"))
    });
    d.$el.appendTo($("body"));

    App.bind("app:cancel_native_app_load",function() {
        displayUI(inviteMode);
    });

    App.bind("app:native_app_load",function() {
        window.location = redirectAppUrl;
    });
}


/**
 * Generates an URL that can be opened in one of Twistle's native apps.
 * @param  {String} destination The final URL destination, such as a conversation.
 * @param  {String} urlScheme The URL scheme, like "com.twistle://"
 * @param  {String} regToken A registration token, if one exists.
 */
 function getRedirectAppURL(destination, urlScheme, regToken) {
    if (destination && destination.substring(0,13) === "conversation/") {
        return `${urlScheme}app?action=conversation&convseq=${destination.substring(13)}`;
    }
    else if (regToken) {
        return `${urlScheme}app?action=invite&token=${regToken}`;
    }
    else {
        return null;
    }
}


/**
 * Determines if the user is in 'registration mode' or not.
 * @param  {String} destination The destination URL path.
 * @param  {String} regToken The registration token, if one exists.
 */
function inRegistrationMode(destination, regToken) {
    if (destination && destination.substring(0,13) === "conversation/") {
        return false;
    }
    else if (regToken) {
        return true;
    }
    else {
        return false;
    }
}